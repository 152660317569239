import React from 'react';
import styles from '../styles/PaymentResponse.module.css';

import { ReactComponent as SuccessIcon } from '../assets/payment-success.svg';
import { ReactComponent as FailureIcon } from '../assets/payment-failure.svg';

interface Props {
  type: 'success' | 'failure';
}

function PaymentResponse({ type }: Props) {
  const title = type === 'success' ? 'Paiement réussi !' : 'Echec du paiement';

  const subtitle =
    type === 'success'
      ? 'Merci pour votre paiement. Montrez votre reçu à l’apprenti'
      : 'Nous sommes désolé mais votre paiement n’a pas abouti!';

  return (
    <div className={styles.container}>
      {type === 'success' ? (
        <SuccessIcon className={styles.image} />
      ) : (
        <FailureIcon className={styles.image} />
      )}
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
}

export default PaymentResponse;
