import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import styles from '../styles/PaymentTicket.module.css';

interface Props {
  amount: number;
  phone: string;
  createdAt: string;
  ticketNumber: string;
}

function PaymentTicket({ amount, phone, createdAt, ticketNumber }: Props) {
  const formatTime = (): string => {
    return (
      new Date(createdAt).toLocaleDateString() +
      ' à ' +
      new Date(createdAt).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  };

  return (
    <div className={styles.container}>
      {ticketNumber && (
        <p className={styles.ticketNumber}>Billet N°: {ticketNumber}</p>
      )}
      <h2 className={styles.title}>
        <span className={styles.amount}>{amount}</span>
        <span className={styles.currency}>FCFA</span>
      </h2>
      <p className={`${styles.subtitle} ${styles.mb10}`}>
        {formatPhoneNumber(phone)}
      </p>
      <p className={styles.subtitle}>{formatTime()}</p>
    </div>
  );
}

export default PaymentTicket;
