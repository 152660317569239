import React from 'react';
import styles from '../styles/PrimaryBtn.module.css';

interface Props {
  disabled?: boolean;
  text: string;
  onClick: any;
}

function PrimaryBtn({ text, onClick, disabled = false }: Props) {
  return (
    <button
      className={`${styles.primaryBtn} ${disabled && styles.disabled}`}
      onClick={onClick}
    >
      <p>{text}</p>
    </button>
  );
}

export default PrimaryBtn;
