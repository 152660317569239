import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/OTPCodeInput.module.css';

interface Props {
  numberOfDigits: number;
  label: string;
  setValue: any;
  error: boolean;
}

function OTPCodeInput({
  numberOfDigits,
  label,
  setValue,
  error = false,
}: Props) {
  const otpBoxReference = useRef<any>([]);
  const [otpDigits, setOtpDigits] = useState<any>(
    new Array(numberOfDigits).fill(''),
  );

  useEffect(() => {
    if (otpDigits.join('') !== '') {
      setValue(otpDigits.join(''));
    }
  }, [otpDigits, setValue]);

  function handleChange(value: any, index: any) {
    let newArr = [...otpDigits];
    newArr[index] = value;
    setOtpDigits(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e: any, index: number) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={styles.inputs}>
        {otpDigits.map((digit: string, index: number) => (
          <input
            className={`${styles.digitInput} ${error && styles.error}`}
            inputMode="numeric"
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
          />
        ))}
      </div>
    </div>
  );
}

export default OTPCodeInput;
