import React from 'react';
import styles from '../styles/PaymentResult.module.css';

import { Loader } from '../components';

function PaymentRedirection() {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.waveInfo}>
          <Loader />
          <p className={styles.title}>
            Vous êtes redirigé vers le service de paiement sécurisé.
          </p>
        </div>
      </div>
    </>
  );
}

export default PaymentRedirection;
