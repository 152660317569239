import React from 'react';
import styles from '../styles/PaymentResult.module.css';

import { Loader } from '../components';

function PaymentPending() {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.processing}>
          {/* <img alt="loading..." src={processing} /> */}
          <Loader />
          <p className={styles.title}>Traitement du paiement</p>
        </div>
      </div>
    </>
  );
}

export default PaymentPending;
