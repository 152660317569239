import styles from '../styles/PaymentDetails.module.css';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import orangeLogo from '../assets/orangeLogo.png';
import waveLogo from '../assets/waveLogo.png';
import { useAuth, isDev } from '../context';
import {
  Header,
  PrimaryBtn,
  Loader,
  VehicleNotFound,
  VehicleNotActive,
  LoaderOverlay,
} from '../components';
import { createPayment } from '../api/PaymentApi';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import { vehicleHasOngoingShift, vehicleIsValid } from '../api/VehicleApi';

function PaymentDetails() {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const { userId } = useAuth();

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [creationError, setCreationError] = useState<string>('');

  const [amount, setAmount] = useState<string>('');
  const [amountError, setAmountError] = useState<string>('');

  const [phone, setPhone] = useState<any>();
  const [phoneError, setPhoneError] = useState<string>('');

  const [selectedOperator, setSelectedOperator] = useState<
    'orange' | 'wave' | ''
  >('');

  const [validatingVid, setValidatingVid] = useState<boolean>(true);
  const [vehicleExists, setVehicleExists] = useState<boolean>(false);
  const [shiftExists, setShiftExists] = useState<boolean>(false);

  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (vehicleId && userId) {
        const vehicleMatch = await vehicleIsValid(vehicleId.toString());

        if (vehicleMatch) {
          setVehicleExists(true);
          const hasOngoingShift = await vehicleHasOngoingShift(
            vehicleId.toString(),
          );
          setShiftExists(hasOngoingShift);
        }

        setValidatingVid(false);
      }
    })();
  }, [vehicleId, userId]);

  useEffect(() => {
    if (
      !amount ||
      Number.parseInt(amount) <= 0 ||
      !phone ||
      !isValidPhone(phone) ||
      !selectedOperator
    ) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }

    if (Number.parseInt(amount) <= 0) {
      setAmountError('Montant non valide');
    } else {
      setAmountError('');
    }

    if (phone && !isValidPhone(phone)) {
      setPhoneError('téléphone non valide');
    } else {
      setPhoneError('');
    }
  }, [amount, phone, selectedOperator]);

  const handleSubmit = async () => {
    try {
      if (btnDisabled) return;

      if (vehicleId === undefined || userId === null) {
        console.error('vehicleId or userId is undefined');
        return;
      }

      setProcessingPayment(true);

      const paymentId = await createPayment(
        vehicleId,
        Number.parseInt(amount),
        phone,
        selectedOperator,
      );

      if (paymentId) navigate(`/payment/${paymentId}`);
    } catch (error) {
      setCreationError("Nous n'avons pas pu créer le paiement, réessayez.");
      console.error('SUBMIT_PAYMENT_ERROR', error);
    } finally {
      setProcessingPayment(false);
    }
  };

  const isValidPhone = (phone: any) => {
    return isDev() || isValidPhoneNumber(phone);
  };

  return (
    <div className={styles.container}>
      <Header />
      {processingPayment && <LoaderOverlay />}

      {validatingVid && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}

      {!validatingVid && !vehicleExists && <VehicleNotFound />}
      {!validatingVid && vehicleExists && !shiftExists && <VehicleNotActive />}

      {!validatingVid && vehicleExists && shiftExists && (
        <>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Payez votre transport avec mobile money
            </h2>

            <form>
              <div className={styles.formGroup}>
                <label>Montant du transport</label>
                <input
                  placeholder="0 Fcfa"
                  type="number"
                  inputMode="numeric"
                  value={amount}
                  onChange={(ev) => setAmount(ev.target.value)}
                />
                {amountError && <p className={styles.error}>{amountError}</p>}
              </div>

              <div className={styles.formGroup}>
                <label>Numéro de téléphone</label>
                <PhoneInput
                  placeholder="Entrez votre téléphone"
                  country="CI"
                  value={phone}
                  onChange={setPhone}
                />
                {phoneError && <p className={styles.error}>{phoneError}</p>}
              </div>

              <div className={styles.formGroup}>
                <label>Sélectionnez votre opérateur</label>
                <div
                  className={styles.operatorCard}
                  onClick={() =>
                    setSelectedOperator((ps) =>
                      ps === 'orange' ? '' : 'orange',
                    )
                  }
                >
                  <div className={styles.body}>
                    <img src={orangeLogo} alt="Orange Money" />
                    <p>Orange Money</p>
                  </div>
                  <input
                    type="radio"
                    value="orange"
                    checked={selectedOperator === 'orange'}
                  />
                </div>
                <div
                  className={styles.operatorCard}
                  onClick={() =>
                    setSelectedOperator((ps) => (ps === 'wave' ? '' : 'wave'))
                  }
                >
                  <div className={styles.body}>
                    <img src={waveLogo} alt="Wave" />
                    <p>Wave</p>
                  </div>
                  <input
                    type="radio"
                    value="wave"
                    checked={selectedOperator === 'wave'}
                  />
                </div>
              </div>
            </form>

            {creationError && <p className={styles.error}>{creationError}</p>}
          </div>

          <PrimaryBtn
            text={'Payer mon transport'}
            disabled={btnDisabled}
            onClick={handleSubmit}
          />
        </>
      )}
    </div>
  );
}

export default PaymentDetails;
