import React from 'react';
import styles from '../styles/PaymentNotFound.module.css';

import notFound from '../assets/not-found.png';

const PaymentNotFound = () => {
  return (
    <>
      <div className={styles.content}>
        <img className={styles.image} src={notFound} alt="Twende" />
        <p className={styles.title}>Payment non valide</p>
        <p className={styles.subtitle}>
          Veuillez scanner le code QR à nouveau!
        </p>
      </div>
    </>
  );
};

export default PaymentNotFound;
