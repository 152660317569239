import React, { useEffect } from 'react';
import styles from '../styles/PaymentResult.module.css';

import { useParams } from 'react-router';
import { usePayment } from '../context/PaymentContext';

import {
  Header,
  OrangeOTP,
  PaymentFailed,
  PaymentSucceeded,
  PaymentRedirection,
  PaymentPending,
  PaymentNotFound,
} from '../components';

function PaymentResult() {
  const { paymentId } = useParams();
  const { payment, setPaymentId } = usePayment();

  useEffect(() => {
    if (paymentId) {
      setPaymentId(paymentId);
    }
  }, [paymentId, setPaymentId]);

  const RenderPaymentStatus: React.FC<any> = () => {
    switch (payment?.status) {
      case 'OTP_NEEDED':
        return <OrangeOTP />;
      case 'SUCCEEDED':
        return <PaymentSucceeded />;
      case 'FAILED':
        return <PaymentFailed />;
      case 'REDIRECTION':
        return <PaymentRedirection />;
      case 'NOT_FOUND':
        return <PaymentNotFound />;
      case 'PENDING':
      default:
        return <PaymentPending />;
    }
  };

  return (
    <div className={styles.container}>
      <Header />
      <RenderPaymentStatus />
    </div>
  );
}

export default PaymentResult;
