import React, { useState, useEffect } from 'react';
import styles from '../styles/OrangeOTP.module.css';
import orangeLogo from '../assets/orangeLogo.png';

import { useParams } from 'react-router';
import { usePayment } from '../context';
import { validateOTP } from '../api/PaymentApi';
import { OTPCodeInput, PrimaryBtn } from '.';

function OrangeOTP() {
  const numberOfDigits = 4;
  const { paymentId } = useParams();
  const { setLoading } = usePayment();

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [otpCode, setOtpCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (otpCode && otpCode.length === numberOfDigits) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
      setError('');
    }
  }, [otpCode]);

  const validateOtpCode = async () => {
    if (paymentId) {
      try {
        setLoading(true);
        await validateOTP(paymentId, otpCode);
      } catch (error) {
        setError('Code non valide');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.orangeInfo}>
          <img src={orangeLogo} alt="Orange Money Logo" />
          <p className={styles.title}>Cliquez ici pour obtenir un code</p>
          <a className={styles.callOrange} href="tel:%23144%2A82%23">
            #144*82#
          </a>
        </div>

        <OTPCodeInput
          numberOfDigits={numberOfDigits}
          label="Saisissez le code"
          setValue={setOtpCode}
          error={!!error}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>

      <PrimaryBtn
        disabled={btnDisabled}
        text="Valider"
        onClick={validateOtpCode}
      />
    </>
  );
}

export default OrangeOTP;
