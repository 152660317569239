import { httpsCallable } from 'firebase/functions';
import { doc, updateDoc } from 'firebase/firestore';

import { ew6Functions, db } from '../config/firebase-config';

export async function createPayment(
  vehicleId: string,
  amount: number,
  phone: string,
  provider: string,
): Promise<string | undefined> {
  try {
    const startBookingPayment = httpsCallable<
      PaymentBookingRequest,
      PaymentBookingResponse
    >(ew6Functions, 'payBooking');

    const payBookingRequest = new PaymentBookingRequest(
      vehicleId,
      phone,
      amount,
      'XOF',
      new PaymentMethod(provider, phone),
    );

    const response = await startBookingPayment(payBookingRequest);

    return response.data.paymentId;
  } catch (error) {
    console.error('CREATE_PAYMENT_ERROR', error);
    throw error;
  }
}

export async function validateOTP(paymentId: string, optCode: string) {
  try {
    const attemptValidateOTP = httpsCallable(ew6Functions, 'validateOTP');

    const validateOTPRequest = {
      paymentId: paymentId,
      otpCode: optCode,
    };

    await attemptValidateOTP(validateOTPRequest);
  } catch (error) {
    console.error('VALIDATE_OTP_ERROR', error);
    throw error;
  }
}

export async function updatePaymentStatus(paymentId: string, status: string) {
  try {
    const paymentRef = doc(db, 'Payments', paymentId);
    await updateDoc(paymentRef, { status: status });
  } catch (error) {
    console.error('UPDATE_PAYMENT_STATUS_ERROR', error);
    throw error;
  }
}

class PaymentBookingRequest {
  vehicleId: string;
  phone: string;
  bookingType: string = 'singleRoute';
  amount: number;
  currency: string;
  paymentMethod: PaymentMethod;

  constructor(
    vehicleId: string,
    phone: string,
    amount: number,
    currency: string,
    paymentMethod: PaymentMethod,
  ) {
    this.vehicleId = vehicleId;
    this.phone = phone;
    this.amount = amount;
    this.currency = currency;
    this.paymentMethod = paymentMethod;
  }
}

class PaymentMethod {
  provider: string;
  phone: string;
  type: string = 'MOBILE_MONEY';

  constructor(provider: string, phone: string) {
    this.provider = provider;
    this.phone = phone;
  }
}

class PaymentBookingResponse {
  paymentId: string;

  constructor(paymentId: string) {
    this.paymentId = paymentId;
  }
}
