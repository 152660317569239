import React from 'react';
import styles from '../styles/VehicleNotActive.module.css';

import notFound from '../assets/not-found.png';

const VehicleNotActive = () => {
  return (
    <>
      <div className={styles.content}>
        <img className={styles.image} src={notFound} alt="Twende" />
        <p className={styles.title}>Vehicule n'est pas actif</p>
        <p className={styles.subtitle}>
          Le chauffeur doit démarrer le système pour accepter les paiements avec
          mobile money.
        </p>
      </div>
    </>
  );
};

export default VehicleNotActive;
