import React from 'react';
import styles from '../styles/PaymentResult.module.css';
import { PaymentResponse, PaymentTicket } from '../components';
import { usePayment } from '../context';

function PaymentSucceeded() {
  const { payment } = usePayment();

  return (
    <>
      <PaymentResponse type="success" />

      <div className={styles.content}>
        <PaymentTicket
          amount={payment.amount}
          phone={payment.phone}
          createdAt={payment.createdAt}
          ticketNumber={payment?.ticketNumber}
        />
      </div>
    </>
  );
}

export default PaymentSucceeded;
