import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthProvider, PaymentProvider } from './context';

import { PaymentLoaderOverlay } from './components';
import { PaymentDetails, PaymentResult, NotFound } from './pages';

const router = createBrowserRouter([
  {
    index: true,
    path: '/checkout/:vehicleId',
    element: <PaymentDetails />,
  },
  {
    path: '/payment/:paymentId',
    element: (
      <PaymentProvider>
        <PaymentLoaderOverlay />
        <PaymentResult />
      </PaymentProvider>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
