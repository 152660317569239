import React from 'react';
import styles from '../styles/PaymentResult.module.css';
import { PaymentResponse, PrimaryBtn } from '../components';
import { useNavigate } from 'react-router';
import { usePayment } from '../context';

function PaymentFailed() {
  const navigate = useNavigate();
  const { payment } = usePayment();

  return (
    <>
      <PaymentResponse type="failure" />

      <div className={styles.content}></div>

      <PrimaryBtn
        text="Réessayer le paiement"
        onClick={() => navigate(`/checkout/${payment?.vehicleId}`)}
      />
    </>
  );
}

export default PaymentFailed;
