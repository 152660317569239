import {
  getDocs,
  query,
  collectionGroup,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';

import { db } from '../config/firebase-config';

export async function vehicleHasOngoingShift(
  vehicleId: string,
): Promise<boolean> {
  try {
    const shiftQuery = query(
      collectionGroup(db, 'Shifts'),
      where('status', '==', 'ONGOING'),
      where('vehicleId', '==', vehicleId),
    );

    const shiftSnapshot = await getDocs(shiftQuery);

    if (shiftSnapshot.empty) {
      throw new Error('Shift not found!');
    }

    if (shiftSnapshot.docs.length > 1) {
      throw new Error('Too many shifts ongoing!');
    }

    return true; // Ongoing shift found
  } catch (error: any) {
    console.error(error.message);
    return false;
  }
}

export async function vehicleIsValid(vehicleId: string): Promise<boolean> {
  try {
    const vehicleRef = doc(db, 'Vehicles', vehicleId.toString());
    const vehicleSnapshot = await getDoc(vehicleRef);
    return vehicleSnapshot.exists();
  } catch (error: any) {
    console.error(error.message);
    return false;
  }
}
