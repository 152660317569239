import React from 'react';
import styles from '../styles/LoaderOverlay.module.css';

import { usePayment } from '../context/PaymentContext';

interface Props {
  color?: string;
}

function PaymentLoaderOverlay({ color = '#cd545b' }: Props) {
  const { loading } = usePayment();

  return (
    loading && (
      <div className={styles.container}>
        <div className={styles.spinner}>
          <svg height="100%" viewBox="0 0 32 32" width="100%">
            <circle
              cx="16"
              cy="16"
              fill="none"
              r="14"
              stroke-width="4"
              style={{
                stroke: color,
                opacity: 0.2,
              }}
            ></circle>
            <circle
              cx="16"
              cy="16"
              fill="none"
              r="14"
              stroke-width="4"
              style={{
                stroke: color,
                strokeDasharray: 80,
                strokeDashoffset: 60,
              }}
            ></circle>
          </svg>
        </div>
      </div>
    )
  );
}

export default PaymentLoaderOverlay;
