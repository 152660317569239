/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, createContext } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import { updatePaymentStatus } from '../api/PaymentApi';

interface Payment {
  status: string;
  redirectUrl?: string;
}

const PaymentContext = createContext<any>(null);

export const usePayment = () => {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePayment must be used within an PaymentContextProvider');
  }

  return context;
};

interface PaymentProviderProps {
  children: React.ReactNode;
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({
  children,
}) => {
  const [payment, setPayment] = useState<Payment | undefined>(undefined);
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (paymentId !== null) {
      const subscriber = onSnapshot(doc(db, 'Payments', paymentId), (doc) => {
        const paymentData = doc.exists()
          ? (doc.data() as Payment)
          : { status: 'NOT_FOUND' };
        setPayment(paymentData);

        if (loading) {
          setLoading(false);
        }
      });

      return () => subscriber();
    }
  }, [paymentId]);

  useEffect(() => {
    if (payment?.status === 'REDIRECTION' && payment.redirectUrl) {
      setTimeout(() => {
        window.open(payment.redirectUrl, '_top');
        if (paymentId !== null) {
          updatePaymentStatus(paymentId, 'PENDING');
        }
      });
    }
  }, [payment?.status]);

  return (
    <PaymentContext.Provider
      value={{ payment, setPaymentId, loading, setLoading }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
