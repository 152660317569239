import React from 'react';
import styles from '../styles/NotFound.module.css';
import { Header } from '../components';
import notFound from '../assets/not-found.png';

function NotFound() {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <img className={styles.image} src={notFound} alt="Twende" />
        <h3 className={styles.title}>Oops! Une erreur s'est produite</h3>
        <p className={styles.subtitle}>
          Nous sommes désolés, mais quelque chose ne fonctionne pas comme prévu.
        </p>
        <p className={styles.title}>
          <b>Veuillez scanner le code QR à nouveau !</b>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
