import React from 'react';
import styles from '../styles/Header.module.css';
import logo from '../assets/logo-full.png';

function Header() {
  return (
    <div className={styles.header}>
      <img src={logo} alt="Twende" />
    </div>
  );
}

export default Header;
